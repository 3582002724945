<template>
  <div v-if="!isEmpty(options)" class="flex cursor-pointer">
    <div
      v-for="(item, index) in optionsArray.slice(0, requiredItems)"
      :key="index"
      class="flex flex-row"
    >
      <Chips
        class="bg-white"
        :class="chipswidth(optionsArray)"
        :child-class="childClass"
        :url="item && item.image ? item.image : null"
        :title="getLabel(item)"
        :name-initials="`${item.first_name} ${item.last_name}`"
        :description="getDetail(item) ? getDetail(item) : ''"
        :image="image"
        :dropdown-top-bottom="dropdownTopBottom"
        :tooltip="tooltip"
        :tooltip-title="tooltipTitle"
      />
    </div>
    <div v-if="optionsArray.length > 3" class="flex items-center gap-1 justify-center">
      <span>...</span>
      <div
        class="badge cursor-pointer relative justify-center flex items-center border-2 border-primary-purple-50 bg-primary-purple-100 rounded-full text-sm md:text-base font-roboto font-medium w-6 h-6"
      >
        <p
          class="items-center text-primary-purple-600 text-xs font-medium leading-[18px] p-1.5"
          @click.stop="showAllOptions()"
        >
          +{{ updateBadge }}
        </p>
        <div
          v-if="allOptions"
          class="selected-options-container zindex border border-text-color-info bg-white text-text-color absolute w-56 rounded-md"
        >
          <ul class="text-base px-2">
            <li
              v-for="(item, idx) in optionsArray"
              :key="idx"
              class="flex optionsBorder border-border-line py-1"
            >
              <div v-if="image">
                <div v-if="item.image !== null" class="w-7 h-7">
                  <img :src="item.image" class="h-full w-full rounded-full" />
                </div>
                <span
                  v-else-if="item.image === null"
                  class="h-7 w-7 rounded-full flex justify-center items-center text-sm bg-primary-purple-50 text-primary-purple-600"
                >
                  {{ initials(`${item.first_name} ${item.last_name}`) }}
                </span>
              </div>

              <div class="text-sm truncate w-4/5 p-2 flex flex-col justify-center">
                <div class="text-xs">
                  {{ getLabel(item) }}
                </div>
                <div class="text-xxs">
                  {{ getDetail(item) }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="font-roboto font-normal text-text-color text-sm px-5">
    {{ defaultFalsyTitle }}
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import generalUtil from '@src/mixins/general-mixins.js'
import Chips from '@src/components/UiElements/Chips.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'

export default {
  components: { Chips },
  mixins: [generalUtil],

  props: {
    profile: {
      type: Boolean,
      default: false,
    },
    badgeMargin: {
      type: String,
      default: 'rtl:-mr-10 ltr:-ml-10',
    },
    childClass: {
      type: String,
      default: '',
    },
    dropdownTopBottom: {
      type: String,
      default: 'top-6',
    },
    tooltip: {
      type: String,
      default: 'tooltip-top',
    },
    dynamicChipWidth: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    nestedLabel: {
      type: Array,
      default: () => [],
    },
    nestedDetail: {
      type: Array,
      default: () => [],
    },
    requiredItems: { type: Number, default: 3 },
    label: { type: [String, Number], default: 'title' },
    reduce: { type: String, default: '' },
    url: { type: String, default: '' },
    detail: { type: [String, Number], default: '' },
    isObjectConcated: {
      type: Boolean,
      /* To avoid ripple effect */
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    image: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
    defaultFalsyTitle: {
      type: String,
      default: GENERAL_CONSTANTS.NOT_APPLICABLE,
    },
  },
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      allOptions: false,
      optionsArray: [],
    }
  },
  computed: {
    updateBadge() {
      return this.optionsArray.length - 3
    },
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        if (Array.isArray(this.options)) {
          this.optionsArray = JSON.parse(JSON.stringify(this.options))
        } else if (this.isObjectConcated) {
          /* OLD LOGIC INCREASE BAGES */
          this.optionsArray.push(this.options)
        } else {
          /* NEW LOGIC RESIST BADGE LENGTH */
          this.optionsArray = [this.options]
        }
      },
    },
  },
  methods: {
    chipswidth(optionsArray) {
      if (!this.dynamicChipWidth)
        return optionsArray.length === 1
          ? 'w-44'
          : optionsArray.length === 2
          ? 'w-25.5'
          : 'w-[70px]'
    },
    /**
     * Show All Options
     * @description Toggle all items via tooltip on clicking Badge
     */
    showAllOptions() {
      this.allOptions = !this.allOptions
    },
    /**
     * Get Detail
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getDetail(option) {
      if (typeof option === 'object') {
        if (this.nestedDetail.length > 0) {
          let nested = option

          this.nestedDetail.forEach((op) => {
            nested = nested[op]
          })
          return nested
        } else return option[this.detail]
      }
      return option
    },
    /**
     * Get Label
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getLabel(option) {
      if (typeof option === 'object') {
        if (this.nestedLabel.length > 0) {
          let nested = option

          this.nestedLabel.forEach((op) => {
            if (nested) nested = nested[op]
          })

          return nested
        } else return option[this.label]
      }
      return option
    },
  },
}
</script>

<style lang="scss" scoped>
.optionsBorder {
  border-bottom: 0.5px solid rgb(219, 216, 216);
}
.optionsBorder:last-child {
  border-bottom: none;
}

ul {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  list-style: none;
}
.selected-options-container {
  top: 43px;
  right: -12px;
  z-index: 20;
  min-height: 40px;
  transition: opacity 0.3s;
  &::before {
    position: absolute;
    top: -7px;
    right: 20px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
}
</style>
