import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import SECTION_FEE_DATA from '@src/router/views/finance/fees/section-fee/sectionFee.json'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (count, currencySymbol, currentCampusScope, currentSectionScope) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        { text: 'TOTAL_NO_SECTIOS_FEE', value: count, tooltipDescription: 'Total no of Sections' },
      ],
    },
  ]
  return stats
}
export const SectionFeeGraphChart = (currencySymbol) => {
  let PIE_LABELS = [`Total Remaining Fee ${currencySymbol}`, `Total Received Fee ${currencySymbol}`]
  let PIE_GRAPH_VALUE = [20000, 30000]
  const chartData = [
    GRAPH_CONSTANTS.PIE_CHART(
      PIE_LABELS,
      PIE_GRAPH_VALUE,
      SECTION_FEE_DATA.PIE_BACKGROUND_COLOR,
      SECTION_FEE_DATA.PIE_TITLE,
    ),
  ]
  return chartData
}
