<template>
  <div>
    <SectionFeeFilters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(sectionFees)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(
                filtersData,
                head.dataTableName,
                SECTION_FEE_DATA.defaultShownHeaders,
              )
            "
            :key="head.id"
          >
            <span v-i18n="fees">
              {{ head.dataTableName }}
            </span>
          </TH>
          <TH v-permission="campusScopePermisions">Class</TH>
          <TH v-permission="campusClassScopePermission">Section</TH>
          <TH>Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(fee, index) in sectionFees" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Fee Title', ['Fee Title'])">
            <router-link
              :to="{ name: 'section-fee-details', params: { id: fee.id } }"
              class="text-primary-purple-600 cursor-pointer"
            >
              {{ fee.title }}
            </router-link>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description')">
            {{ fee.description }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Fee Type', ['Fee Type'])">
            {{ fee.fee_type.title }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Total Fee', ['Total Fee'])">
            {{ fee.fee_amount }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Currency')">
            <span class="uppercase">
              {{ fee.currency }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Due Date', ['Due Date'])">
            {{ $filters.getTimeOnly(fee.due_date) }}
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
            v-permission="campusScopePermisions"
          >
            <BadgeDisplay :options="[fee.section.class]" label="title" class="pl-8" />
          </TD>
          <TD v-permission="campusClassScopePermission">
            <BadgeDisplay :options="[fee.section]" label="title" class="pl-8" />
          </TD>
          <TD>
            <TableAction
              :current-user="fee"
              :action-list="actionList"
              :idx="index"
              @action="feeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>

    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(sectionFeeCount, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <ValidationObserver @submit="addSectionFee">
      <!-- Add Section Fee -->
      <UiModalContainer
        v-if="showModal"
        footer-classes="flex justify-end gap-3"
        :modal-show="showModal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span v-i18n="fees">Add Section Fee</span>
        </template>
        <template v-slot>
          <div>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="classFeeData.fee_type_id"
                title="FEE_TYPE"
                :options="feeTypes"
                label="title"
                :class="$style.widthItems"
                class="flex-1"
                reduce="id"
                rules="required"
                :search-through-api="true"
                @search="fetchFeeTypes"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiInput
                v-model.number="classFeeData.fee_amount"
                type="text"
                name="Amount"
                title="Amount"
                label="Amount"
                placeholder="Amount"
                :class="$style.widthItems"
                class="flex-1 w-full"
                rules="required|numeric"
              />
              <UiInput
                v-model="classFeeData.payment_method"
                type="text"
                name="Payment Method"
                title="Payment Method"
                label="Payment Method"
                placeholder="Payment Method"
                :class="$style.widthItems"
                class="flex-1 w-full"
                rules="alpha_numeric_spaces"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiDatePicker
                v-model="classFeeData.due_date"
                title="Due Date"
                :class="$style.widthItems"
                class="flex-1"
                :date-mode="DATE_MODE.END_DATE_TIME"
                rules="required"
              />
              <UiSingleSelect
                v-model="classFeeData.currency"
                :rules="'required'"
                :options="allowedCurrencies"
                reduce="id"
                title="CURRENCY_FOR_FEE"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper v-if="!currentSectionScope">
              <UiSingleSelect
                v-model="classFeeData.class_id"
                title="CLSS"
                :options="classList"
                label="title"
                :class="$style.widthItems"
                class="flex-1"
                :disabled="!!currentClassScope"
                reduce="id"
                rules="required"
                @change="setSection(classFeeData.class_id)"
              />
              <UiSingleSelect
                v-model="classFeeData.section_id"
                title="Section"
                :options="sectionsList"
                label="title"
                :class="$style.widthItems"
                class="flex-1"
                reduce="id"
                rules="required"
              />
            </InputFieldWrapper>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex gap-3 rtl:gap-x-4">
            <UIButton @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary" :disabled="isLoading">
              <span v-if="isLoading">
                <Loader />
              </span>
              <span v-else>
                <span>Save</span>
              </span>
            </UIButton>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
    <UIConfirmationModal
      heading="DELETE_SECTION_FEE"
      message="Are you sure you want to delete section fee?"
      button-text="Delete"
      :modal="confirmation"
      @cancel="toggleConfirmationModal"
      @confirm="deleteFeeByID"
    />
  </div>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import SectionFeeFilters from '@src/router/views/finance/fees/section-fee/SectionFeeFilter.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralUtil from '@src/mixins/general-mixins.js'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { objectDeepCopy, findCurrencyById, showColumnInDataTable } from '@utils/generalUtil'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import SECTION_FEE_DATA from '@src/router/views/finance/fees/section-fee/sectionFee.json'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixin from '@src/mixins/file-mixins'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { isEmpty } from 'lodash'
import {
  rightBarStats,
  SectionFeeGraphChart,
} from '@src/router/views/finance/fees/section-fee/SectionFee.util.js'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    UiSingleSelect,
    TableAction,
    BadgeDisplay,
    SectionFeeFilters,
    Pagination,
    Loader,
    UiInput,
    UiDatePicker,
    ValidationObserver,
    UiModalContainer,
    UIConfirmationModal,
    NoRecordFound,
    UIButton,
    InputFieldWrapper,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },

  mixins: [GeneralUtil, fileMixin, scrollMixin],
  data() {
    return {
      isEmpty,
      DATE_MODE,
      GENERAL_CONSTANTS,
      SCOPE_LEVELS,
      SECTION_FEE_DATA,
      filtersData: {},
      sectionsList: [],
      classList: [],
      tableHeaders: SECTION_FEE_DATA.tableHeaders,
      confirmation: false,
      campusScopePermisions: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
      },
      classLevelPermissions: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL],
      },
      campusClassScopePermission: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
      },
      defaultSelectedColumns: SECTION_FEE_DATA.defaultSelectedColumns,
      fees: 'fees',
      dashboard: 'dashboard',
      actionList: [{ name: 'Delete' }],
      filteredRecordLimit: 10,
      isLoading: false,
      currentUserPosition: '',
      currentUser: {},
      showPagination: false,
      classFeeData: {
        fee_type_id: '',
        fee_amount: '',
        status: 'Due',
        payment_method: '',
        due_date: '',
        currency: '',
        class_id: '',
        section_id: '',
      },
    }
  },

  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      sectionFees: (state) => state.fee.classFees?.records,
      feeTypes: (state) => state.fee.feeTypes?.records,
      sectionFeeCount: (state) => state.fee.classFees?.meta.total_records || 0,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentCampusScope: (state) => state.layout.currentCampusScope?.id,
      currentClassScope: (state) => state.layout.currentClassScope,
      defaultCurrencyForFee: (state) =>
        state.settings?.siteSetting?.default_currency?.default_currency,
    }),
    currencySymbol() {
      return findCurrencyById()?.symbol
    },
    ...mapGetters('layout', ['allowedCurrencies']),
  },
  watch: {
    currentCampusScope: {
      handler(value) {
        if (value) this.filterRecord()
      },
    },
    currentSectionScope: {
      handler() {
        this.filterRecord()
      },
    },
    currentClassScope: {
      handler() {
        this.filterRecord()
      },
    },
    showModal: {
      deep: true,
      handler(value) {
        if (value) {
          this.fetchFeeTypes()
          if (this.currentCampusScope) this.getCampusClasses()
        }
      },
    },
  },
  created() {
    this.initData()
  },
  /**
   * Mounted Hook
   * @description Calls the filterRecord()
   */
  mounted() {
    this.setRightBarData()
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    initData() {
      if (this.defaultCurrencyForFee) this.classFeeData.currency = this.defaultCurrencyForFee
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    getCampusClasses() {
      let payload = {
        campus_id: this.currentCampusScope,
      }
      this.getClassesList(payload).then((res) => {
        this.classList = res.records
        if (this.currentClassScope) {
          this.classFeeData.class_id = this.currentClassScope?.id
          this.setSection(this.classFeeData.class_id)
        }
      })
    },
    fetchFeeTypes(query = '') {
      let queryPayload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query) } }
      this.getFeeTypes(queryPayload)
    },
    setSection(id) {
      this.classList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsList = classItem.sections
      })
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
    },
    /**
     * Handle Click
     * @param {string} eventName
     * @returns {void}
     * @description Calls the setShowModal, setShowUiModal and resetFeeData on the basis of switch cases
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          this.setShowUiModal(true)
          break
        case 'close':
          this.setShowModal(false)
          this.setShowUiModal(false)
          this.resetFeeData()
          break

        case 'confirm':
          break
      }
    },
    /**
     * Filter Record
     * @param {number} range
     * @returns {void}
     * @description Method description
     * -  Filter the record on the basis of range
     * -  Setting right bar data
     */
    filterRecord(range) {
      if (!this.currentCampusScope) return
      this.isLoading = true
      let paginationRange = paginationRangeHandler(range)
      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getClassStudentFees(payload)
        .then(() => {
          this.showPagination = this.sectionFeeCount > 10
          this.setRightBarData(this.sectionFeeCount)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Set Right Bar Data
     * @description Set RightBar data after API response with real stats
     */
    setRightBarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Section Fee',
          buttons: [
            {
              title: 'Add Section Fee',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: rightBarStats(
          count,
          this.currencySymbol,
          this.currentCampusScope,
          this.currentSectionScope,
        ),
        chartData: SectionFeeGraphChart(this.currencySymbol),
      }
      this.setRightbarContent(rightBarContent)
    },
    /**
     * Fee Action
     * @param {string} action
     * @param {number} currentStudent
     *  @param {number} idx
     * @returns {void}
     * @description Delete fee on the basis of action
     */
    // TODO: Function name is not well defined. It is not defining its functionality
    feeAction(action, currentStudent, idx) {
      switch (action) {
        case 'Delete':
          this.currentUser = currentStudent
          this.currentUserPosition = idx
          this.toggleConfirmationModal()
          break
      }
    },
    /**
     * Add Section Fee
     * @param {boolean} invalid
     * @returns {void}
     * @description Method description
     * -  Create new class fee
     * -  Format date YYYY-MM-DD format and then pass as param in API call
     * -  Close modal after API successful response and reset the form
     */
    addSectionFee() {
      this.isLoading = true
      this.addStudentFees(this.classFeeData)
        .then((response) => {
          const title = response?.data?.title
          this.$store.commit(
            'toast/NEW',
            { type: 'success', message: `${title} ${this.$t(`toast.successfully created`)}` },
            { root: true },
          )
          this.isLoading = false
          this.setShowModal(false)
          this.resetFeeData()
          this.filterRecord()
          this.setRightBarData(this.sectionFeeCount)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Delete Fee
     * @param {number} id
     * @param {number} idx
     * @returns {void}
     * @description Method description
     * -  Deleting fee on the basis of id
     * -  Setting right bar data
     */
    deleteFeeByID() {
      const title = this.currentUser?.title
      this.deleteClassFee(this.currentUser.id).then(() => {
        this.$store.commit(
          'toast/NEW',
          { type: 'success', message: `${title} ${this.$t(`toast.is deleted successfully`)}` },
          { root: true },
        )

        this.$store.commit('fee/DELETE_CLASS_FEE', this.currentUserPosition)
        this.filterRecord()
        this.toggleConfirmationModal()
      })
    },
    /**
     * Reset Fee Data
     * @description Reset the fee data to the empty value
     */
    resetFeeData() {
      this.classFeeData.fee_type_id = ''
      this.classFeeData.fee_amount = ''
      this.classFeeData.due_date = ''
      this.classFeeData.payment_method = ''
      this.classFeeData.class_id = ''
      this.classFeeData.section_id = ''
      this.classFeeData.currency = this.defaultCurrencyForFee
    },
    ...mapActions('layout', [
      'setRightbarContent',
      'setShowModal',
      'setShowUiModal',
      'getFilteredClassList',
    ]),
    ...mapActions('fee', [
      'getClassStudentFees',
      'deleteClassFee',
      'getFeeTypes',
      'addStudentFees',
    ]),
    ...mapActions('classes', ['getClassesList']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
